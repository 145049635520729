import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const InfoPage = () => (
  <Layout>
    <SEO title="Info" />
    <h1>INFO</h1>
  </Layout>
)

export default InfoPage
